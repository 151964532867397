@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: "Poppins", sans-serif;
  background-color: #fff;
}
.container {
  max-width: 80%;
  margin: auto;
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
a {
  text-decoration: none;
  color: #000;
  text-transform: capitalize;
}
ul {
  list-style-type: none;
}
button {
  border-radius: 5px;
  padding: 17px 30px;
  background: #1a2138 ;
  border: none;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
}
button i {
  margin-right: 5px;
}
.heading {
  text-align: center;
  width: 60%;
  margin: auto;
  color: white;
}

.heading1 {
  text-align: center;
  width: 100%;
  margin: auto;
  box-shadow: none;
}

.heading p {
  color: #72809d;
}

.heading h1 {
  color: #2d3954;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 35px;
}

.heading1 h1 {
  color: #2d3954;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 35px;
}

.heading1 p {
  color: #72809d;
}
textarea,
input {
  border: none;
  outline: none;
  background: none;
}
.background {
  padding: 80px 0;
  position: relative;
  background: #f7f9fc;
}

.grid5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 30px;
}

.grid4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}

.grid3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  margin:'10%';
}
.grid2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  margin:'10%';
}

.grid1 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 30px;
  margin:'10%';
}




label {
  color: #2d3954;
  font-size: 14px;
}
.mtop {
  margin-top: 50px;
}
h4 {
  font-weight: 500;
  margin-top: 10px;
}
.shadow {
  box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
}
.padding {
  padding: 80px 0;
}
.grid3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.btn2 {
  border-radius: 50px;
  font-size: 14px;
  float: right;
}
.grid4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}
.btn3 {
  background: #ff6922;
  border-radius: 50px;
}
.btn4 {
  background-color: #000;
  color: #fff;
}

.btn5 {
  border-radius: 25px;
  padding: 1% 4%;
  color: #1a2138 ;
  width: 100%;
  font-size: 20px;
  font-weight: 400;
  border: 5px solid #1a2138 1f;
  background: #fff;
}
.back {
  height: 40vh;
  position: relative;
}
.back img {
  height: 40vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: cover;
}
.back::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40vh;
  background: rgba(17, 40, 72, 0.629);
  z-index: -1;
}
.back .container {
  color: #fff;
  padding: 120px 0;
}
.back h1 {
  font-size: 40px;
  font-weight: 500;
}

.btn2{
  background-color: #1a2138 ;
}
.mb {
  margin-bottom: 80px;
}

.header_first_h1{
  font-size: 5vw !important;
}

.header_first_p{
  font-size: 1vw;
}

.login{
    display: block; 
    margin: 5% auto; 
    padding: 2% ;
    width: 60% ;
    background-color: white;
    position: absolute;
    top: 10%;
    transform: translateY(-50%);
    left: 50%;
    transform: translateX(-50%);
    padding: 2%;
}

@media screen and (max-width: 800px) {
  .grid4,
  .grid3,
  .grid5 {
    grid-template-columns: repeat(2, 1fr);
  }
  .heading {
    width: 100%;
  }
  .container {
    max-width: 90%;
  }
  .back::after,
  .back img,
  .back {
    height: 30vh;
  }
}
@media screen and (max-width: 600px) {
  .grid4,
  .grid3,
  .grid5 {
    grid-template-columns: repeat(1, 1fr);
  }
  .heading {
    width: 100%;
  }
  .container {
    max-width: 90%;
  }
  .back::after,
  .back img,
  .back {
    height: 30vh;
  }
}


@media screen and (max-width: 800px) {
  .grid2 {
    grid-template-columns: repeat(1, 1fr);
  }
  .HeadingH1 h1{
    font-size: 14px;
  }

  .header_first{
    margin-top: 30%
  }

  .header_first_h1{
    font-size: 6vw;
  }

  .header_first_p{
    font-size: 3vw;
  }
}



@media screen and (max-width: 600px) {
  .grid2 {
    grid-template-columns: repeat(1, 1fr);
  }
  .HeadingH1 h1{
    font-size: 14px;
  }
 
  .header_first{
    margin-top: 50%;
    
  }

  .header_first_h1{
    font-size: 10vw !important;
  }

  .header_first_p{
    font-size: 4vw;
  }
 
}
